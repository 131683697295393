import { Injectable, Optional } from '@angular/core';
import { ApiFilter } from 'services/api/api-filter';
import { ApiListWrapper } from 'services/api/api-list-wrapper';
import { ApiService } from 'services/api/api.service';
import { FileService } from 'services/file/file.service';
import { appInjector } from 'src/app/app.module';
import { ProductType } from './product-type';
import { DecimalPipe } from '@angular/common';
import { PaymentMethod } from './payment-method';
import { Product } from './product';

/**
 * This service can and should be used to load and send product information for any product.
 */
@Injectable({
  providedIn: 'root'
})
export class ProductService extends ApiService {

  constructor(
    private fileService: FileService,
    private decimalPipe: DecimalPipe,
  ) {
    super('product');
  }

  public get(key: string | number, options?: any): Promise<any> {
    return super.get(key, options).then(data => {
      if (data.image && data.image.value) {
        return this.fileService.getAsUrl(data.image.value).then(url => {
          data.image = url;
          return data;
        });
      }
      return data;
    });
  }

  /**
   * Load a list with overview-information for a given product. The overview-information will be a reduces version of the complete data.
   * This function also intercepts the promise and converts the image to an url.
   * @param productType defines the product to be loaded, see {@link ProductType}
   * @param options are standard HTTP options
   * @returns a promise for a list of products
   */
  public getOverviewList(productType: ProductType, options?: any): Promise<ApiListWrapper> {
    return this.httpGet(productType, 'overview/list', options).then(data => {
      let list = (data && data.list) ? data.list : [];
      if (list && list.length > 0) {
        list.forEach(product => {
          // resolve image
          if (product.image && product.image.value) {
            return this.fileService.getAsUrl(product.image.value).then(url => {
              product.image = url;
              return product;
            });
          }
          // resolve content text
          product.contentText = product.contentText && product.contentText.replace(/<[^>]*>/g, '');
        });
      }
      return data;
    });
  }

  public getList(filter?: ApiFilter | any): Promise<ApiListWrapper> {
    return super.getList(filter).then(data => {
      let list = (data && data.list) ? data.list : [];
      if (list && list.length > 0) {
        list.forEach(product => {
          // resolve image
          if (product.image && product.image.value) {
            return this.fileService.getAsUrl(product.image.value).then(url => {
              product.image = url;
              return product;
            });
          }
          // resolve content text
          product.contentText = product.contentText && product.contentText.replace(/<[^>]*>/g, '');
        });
      }
      return data;
    });
  }

  /**
   * Get the payment method textkey for a product.
   * @param paymentMethod the paymentMethod to convert
   * @returns a string that is a textkey
   */
  convertToPaymentMethodTextkey(paymentMethod: PaymentMethod): string {
    switch (paymentMethod) {
      case PaymentMethod.PAYPAL: {
        return 'service.product.label.payment.paypal'
      }
      case PaymentMethod.FREE: {
        return 'service.product.label.payment.free'
      }
      default: {
        return '';
      }
    }
  }

  /**
   * Get the product type textkey for a product.
   * @param productType the productType to convert
   * @returns a string that is a textkey
   */
  convertToProductTypeTextkey(productType: ProductType): string {
    return productType ? 'service.product.label.type.' + productType?.toLowerCase() : '';
  }

  /**
   * Get the price label textkey for a product.
   * @param product the product to convert
   * @returns a string that is a textkey
   */
  convertToPriceLabelTextkey(product: Product): string {
    return (
      product?.payment?.available // product is available for purchase
        ? (
          !!product?.payment?.price // product has a price (is not free)
            ? 'service.product.label.price.unpaid' // return "unpaid" label
            : (
              product?.type == ProductType.ADVICE // is the product an advice
                ? 'service.product.label.article' // return advice label
                : 'service.product.label.price.free' // return default "free" label
            )
        )
        : (
          Array.isArray(product?.orders) && product?.orders?.length
            ? 'service.product.label.price.paid' // return "paid" label
            : 'service.product.label.price.notAvailable' // return "notAvailable" label
        )
    );
  }

  /**
   * Get the price text for a product.
   * @param product the product to convert
   * @returns a string that is the actual price
   */
  convertToPriceText(product: Product): string {
    return (
      product?.payment?.available // product is available for purchase
        && !!product?.payment?.price // product has a price (is not free)
        ? product?.payment?.currency + ' ' + this.decimalPipe.transform(product?.payment?.price, '1.2-2') // return currency and price 
        : '' // return empty string
    );
  }
}
