import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { BranchConfigService } from "services/branch-config/branch-config";
import { CoachService } from 'services/coach/coach.service';
import { UserCoachService } from 'services/coach/user-coach.service';
import { MessageService } from 'services/message/message.service';
import { CometchatService } from "services/nachrichten/cometchat.service";
import { Chat, Nachricht, NachrichtenProvider } from 'services/nachrichten/nachrichten';
import { PaidGoodsService } from "services/paid-goods/paid-goods.service";
import { UserdataService } from 'services/userdata/userdata.service';
import { Video } from 'services/video/video.service';
import { ConstantVariable } from "src/app/constant-variable";
import { DataGroup, DataPage, MetaFile, MetaGroup, MetaId, MetaPage, MetaValue } from 'src/app/metagroup';
import { PSEUDO_ID_ASSIGNED } from 'src/app/pages';

@Component({
  selector: 'page-bewerbungsservice-detail',
  templateUrl: 'bewerbungsservice-detail.html',
  styleUrls: ['bewerbungsservice-detail.scss']
})
export class BewerbungsserviceDetailPage implements OnInit {
  public readonly pageName: string = "BewerbungsserviceDetailPage";

  coachesMeta: MetaPage;
  leistungenMetaGroup: MetaGroup;
  qualifikationenMetaGroup: MetaGroup;
  contactMetaGroup: MetaGroup;
  coach: DataPage;
  anredeById: Map<MetaId, MetaValue>;
  fotoUrl: string;
  showAllFeatures: boolean;
  changemode: boolean = false;
  video: Video;

  constructor(
    private route: ActivatedRoute,
    public injector: Injector,
    public coachesProvider: CoachService,
    public branch: BranchConfigService,
    public coachesSettingsProvider: UserCoachService,
    public message: MessageService,
    public nachrichtenProvider: NachrichtenProvider,
    public paidGoodsProvider: PaidGoodsService,
    public userdataProvider: UserdataService,
    public cometchatProvider: CometchatService,
    public translateService: TranslateService,
    private navController: NavController
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit BewerbungsserviceDetailPage');

    this.showAllFeatures = ConstantVariable.showAllFeatures;
    let addMissingDataGroups = () => {
      this.coachesMeta.groups.forEach(group => {
        if (!this.coach[group.id]) {
          this.coach[group.id] = new DataGroup();
        }
      });
    };

    this.coachesProvider.getMeta().then(data => {
      this.coachesMeta = data;

      let idx;
      idx = this.coachesMeta.groups.findIndex(group => {
        return group.id === 'leistungen'; //NOTE: thus group id "leistungen" is set in stone
      });
      this.leistungenMetaGroup = this.coachesMeta.groups[idx];
      idx = this.coachesMeta.groups.findIndex(group => {
        return group.id === 'qualifikationen'; //NOTE: thus group id "qualifikationen" is set in stone
      });
      this.qualifikationenMetaGroup = this.coachesMeta.groups[idx];
      idx = this.coachesMeta.groups.findIndex(group => {
        return group.id === 'contact'; //NOTE: thus group id "contact" is set in stone
      });
      this.contactMetaGroup = this.coachesMeta.groups[idx];

      // find list of anrede options
      let idxField, idxGroup;
      idxGroup = this.coachesMeta.groups.findIndex(group => {
        idxField = group.fields.findIndex(field => {
          return field.id === 'anrede';
        });
        return idxField !== -1;
      });
      this.anredeById = new Map<MetaId, MetaValue>();
      if (idxGroup !== -1) {
        let anreden = (<MetaValue[]>(this.coachesMeta.groups[idxGroup].fields[idxField].values));
        anreden.forEach(anrede => {
          this.anredeById.set(anrede.id, anrede);
        });
      }

      this.route.paramMap.subscribe((params: ParamMap) =>  {
        const id = params.get('id');
        let promise: Promise<DataPage>;
        if (id === PSEUDO_ID_ASSIGNED) {
          this.changemode = false;
          promise = this.coachesSettingsProvider.getSingleton();
        } else {
          this.changemode = +id !== this.userdataProvider.userdata.coachId;
          promise = this.coachesProvider.get(id);
        }
        promise.then(data => {
          console.log('COACH', data);
          if (data) {
            this.coach = data;
            addMissingDataGroups();
            this.fotoUrl = this.fotoImageUrl(this.coach);
            this.updateCoachData(data);
          } else {
            console.error('got null coach with id ' + id);
          }
        }, error => {
          console.error('cannot get coach with id ' + id, error);
        });
      });
    });
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter BewerbungsserviceDetailPage');
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave BewerbungsserviceDetailPage');
  }

  // TODO: this is old behavior for NachrichtenPage/NachrichtenProvider (mocked only)
  contact(coach: DataPage) {
    let __contact = (chat: Chat) => {
      let nachricht = new Nachricht();
      nachricht.chatId = chat.id;
      nachricht.to = chat.to;
      nachricht.time = Date.now();
      nachricht.text = <string>(<DataGroup>this.coach[this.contactMetaGroup.id]).fieldData.nachricht;
      //FIXME: note that there are fields for person name and person email, which are ignored here (as the logged in user should be known to the backend, isn't it???)
      this.nachrichtenProvider.post(nachricht).then((nachricht: Nachricht) => {
        console.log('successfully contacted coach', nachricht);
        this.navController.pop()
      }, error => {
        console.error('Failed to contact coach', error);
      });
    };
    let _contact = () => {
      this.nachrichtenProvider.getChatToCoach(coach).then((chat: Chat) => {
        __contact(chat);
      }, error => {
        this.nachrichtenProvider.createChatToCoach(coach).then((chat: Chat) => {
          __contact(chat);
        }, error => {
          console.error('Failed to get or create chat to coach', error);
        })
      });
    };
    if (coach.id) {
      this.coachesProvider.update(coach.id, coach).then(data => {
        _contact();
      });
    } else {
      this.coachesProvider.create(coach).then(data => {
        _contact();
      });
    }
  }

  chat(coach: DataPage) {
    let gotochat = (data: any) => {
      this.navController.navigateForward('chat/' + data.userId);
    };
    if (this.branch.config.features.nachrichten || this.showAllFeatures) {
      this.nachrichtenProvider.initializeChat(this.coach).then(
        chatPageData => gotochat(chatPageData),
        error => console.error('Failed chat for coach: ' + this.coach.id + '/' + this.coach.userid, error));
    } else {
      console.log('not allowed to go to ChatPage');
    }
  }

  assign(coach: DataPage) {
    if (coach.id) {
      this.coachesSettingsProvider.update(coach.id, { id: coach.id }).then(result => {
        console.info('successfully assigned selected coach to the user');
        this.message.subject.next({ changedCoach: result });
        this.navController.navigateRoot('bewerbungsservice/' + PSEUDO_ID_ASSIGNED);
        this.userdataProvider.refresh();
      }, error => {
        console.error('failed to assign selected coach to the user');
      });
    } else {
      console.error('cannot assign selected but not already created coach to the user');
    }
  }

  back() {
    // Master- und Detail-Seite sind hier vertauscht, weil Benutzer erstmal seinen eigenen Coach sehen soll
    this.navController.navigateForward('bewerbungsservice');
  }

  fotoImageUrl(coach: DataPage): string {
    if (coach.person && (<DataGroup>coach.person).fieldData && (<DataGroup>coach.person).fieldData.foto) {
      let file: MetaFile = <MetaFile>((<DataGroup>coach.person).fieldData.foto);
      return 'url(data:' + file.filetype + ';base64,' + file.value + ')';
    } else {
      return null;
    }
  }

  updateCoachData(coach: DataPage) {
    this.video = null;
    if (coach && coach.person && (<DataGroup>coach.person).fieldData && (<DataGroup>coach.person).fieldData.descvideo) {
      let url: string = <string>((<DataGroup>coach.person).fieldData.descvideo);
      if (!!url) {
        this.video = new Video();
        this.video.url = url;
      }
    }
  }
}
