import { Injectable } from "@angular/core";
import { ApiService } from "services/api/api.service";
import { User } from "./user";

@Injectable()
export class UserService extends ApiService {

    constructor() {
        super('user');
    }

    /**
     * Get the current user
     * @returns the current user
     */
    getCurrentUser(): Promise<User> {
        return this.get('');
    }

    /**
     * Update the current user
     * @param user the user to update
     * @returns the updated user
     */
    updateCurrentUser(user: User): Promise<User> {
        return this.update('', user);
    }
}
