import { BranchConfig, BranchConfigService } from 'services/branch-config/branch-config';
import { Router } from '@angular/router';
import { Injectable, Optional, Inject, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import {
    LoginCredentials,
    SignupCredentials,
    PasswordResetCredentials,
    PasswordResetChangeCredentials,
    PasswordChangeCredentials,
    FbLoginCredentials,
    LinkedinLoginCredentials,
    BookingsuiteLoginCredentials,
    ApaleoLoginCredentials,
    AppleLoginCredentials
} from './auth-credentials';
import { AuthOptions, ApiAuthOptions } from './auth-config';
import { AuthResponse } from './auth-response.interface';
import { AuthError } from './auth-error';
import { PushNotificationService } from "../push-notification/push-notification.service";
import { EventsService } from 'services/events/events.service';
import { UserdataService } from 'services/userdata/userdata.service';
import { EventKey } from 'services/events/events.keys';

/*
IMPORTANT NOTE:
It should be noted that Angular’s new HttpClient from @angular/common/http is being used here and not the Http class from @angular/http.
If we try to make requests with the traditional Http class, the interceptor won’t be hit, i.e. there will be no X-Auth-Token.
 */

@Injectable()
export class AuthService {
    private userSubject = new Subject<AuthResponse>();
    private stateSubject = new Subject<boolean>();
    private errorSubject = new Subject<AuthError>();
    private _user: AuthResponse;

    public loginRedirect: string = '/';

    get user(): AuthResponse {
        return this._user || (this._user = JSON.parse(localStorage.getItem("AuthResponse") || 'null'));
    }
    set user(value: AuthResponse) {
        const oldState: boolean = !!this._user;
        this._user = value;
        // store:
        let authCookie: string = this.authOptions.preventCookie ? null : (encodeURIComponent(this.authOptions.tokenHeader) +
            "=" + encodeURIComponent(this.authOptions.tokenPrefix +
                (value && value[this.authOptions.tokenProperty]) || '') +
            ";path=/;domain=" + encodeURIComponent(this.domainOfUrl(this.configProvider?.config?.apiBaseUrl)));
        if (!value) {
            localStorage.removeItem("AuthResponse");
            if (!this.authOptions.preventCookie) {
                document.cookie = authCookie + ";max-age=0;expires=Fri, 31 Dec 1970 23:59:59 GMT";
                //console.error('unsetting cookie: ' + authCookie + ";max-age=0;expires=Fri, 31 Dec 1970 23:59:59 GMT");
            }
        } else {
            localStorage.setItem("AuthResponse", JSON.stringify(value));
            if (!this.authOptions.preventCookie) {
                document.cookie = authCookie;
                //console.error('setting cookie: ' + authCookie);
            }
        }
        // broadcast:
        this.userSubject.next(this._user);
        if (oldState !== !!this._user) {
            this.stateSubject.next(!!this._user);
        }
    }

    constructor(
        private http: HttpClient,
        @Optional() @Inject(ApiAuthOptions) public authOptions: AuthOptions,
        public events: EventsService,
        public pushNotificationService: PushNotificationService,
        private userdataProvider: UserdataService,
        private router: Router,
        private configProvider: BranchConfigService
    ) {
        this.authOptions = AuthOptions.mergeAuthOptions(this.authOptions);
        this.userChanged().subscribe(user => {
            userdataProvider.flush();
        });
    }

    domainOfUrl(url: string): string {
        if (!url) return;
        let u = new URL(url);
        return u && u.hostname;
    }

    // subscribe to this to get user object on each change
    // this is fired whenever the user is set, even when staying the same
    userChanged(): Observable<AuthResponse> {
        return this.userSubject.asObservable();
    }

    // subscribe to this to get change in login status as a simple boolean
    // this only fires on actual change of status, i.e. when the user was already logged in before the app started (localStorage), then it does *not* fire any value
    stateChanged(): Observable<boolean> {
        return this.stateSubject.asObservable();
    }

    // subscribe to this to get an error message every time any request fails
    // this fires whenever any request fails (all requests throughout the app) that is intercepted by AuthInterceptor
    // and contains boolean information if it is considered an auth failure and the HttpErrorResponse
    error(): Observable<AuthError> {
        return this.errorSubject.asObservable();
    }

    prepare(input: any): any {
        if (this.authOptions.encodeAs === 'application/json') {
            return input; // default, no conversion
        } else if (this.authOptions.encodeAs === 'application/x-www-form-urlencoded') {
            let str = '', arr = [];
            Object.getOwnPropertyNames(input).forEach(key => {
                arr.push(encodeURI(key) + '=' + encodeURI(input[key]));
            });
            str = arr.join('&');
            return str;
        } else {
            return input; // unknown, ignore
        }
    }

    refresh(): Promise<any> {
        if (!this.user) {
            return Promise.reject('Improper use: OAuth-like refresh attempted without having been logged in in the first place.');
        } else if (typeof this.authOptions.tokenRefreshProperty === 'string' && this.user) {
            let headers = new HttpHeaders();
            headers = headers.set('Content-Type', this.authOptions.encodeAs);
            let refresh = this.prepare({
                refresh_token: this.user[this.authOptions.tokenRefreshProperty],
                ...this.authOptions.tokenRefreshParameters
            });
            return new Promise<AuthResponse>((resolve, reject) => {
                this.http.post<AuthResponse>('/api/' + this.authOptions.urls.login, refresh, { headers: headers }).subscribe(data => {
                    this.user = data; //FIXME: user change is issued (and MUST be issued), but issuing of course reloads everything, since there is no way to identify that it is still the same user
                    resolve(data);
                }, err => {
                    this.user = null;
                    reject(err);
                });
            });
        } else {
            return Promise.reject('Improper use: OAuth-like refresh attempted without tokenRefreshProperty configured.');
        }
    }
    login(login: LoginCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        login.username = !!login && !!login.username ? login.username.trim() : null;
        login = this.prepare(login);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.login, login, { headers: headers }).subscribe(data => {
                this.user = data;
                this.logIntoBackgroundServices(this.user);
                this.events.publish(EventKey.USER_LOGGEDIN);
                this.router.navigate([this.loginRedirect]);
                resolve(data);
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    appleLogin(appleLoginResponse: AppleLoginCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        appleLoginResponse.username = !!appleLoginResponse && !!appleLoginResponse.username ? appleLoginResponse.username.trim() : null;
        appleLoginResponse = this.prepare(appleLoginResponse);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.appleLogin, appleLoginResponse, { headers: headers }).subscribe(data => {
                this.user = data;
                this.logIntoBackgroundServices(this.user);
                this.events.publish(EventKey.USER_LOGGEDIN);
                resolve(data);
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    fbLogin(fbLoginResponse: FbLoginCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        fbLoginResponse.username = !!fbLoginResponse && !!fbLoginResponse.username ? fbLoginResponse.username.trim() : null;
        fbLoginResponse = this.prepare(fbLoginResponse);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.fbLogin, fbLoginResponse, { headers: headers }).subscribe(data => {
                this.user = data;
                this.logIntoBackgroundServices(this.user);
                this.events.publish(EventKey.USER_LOGGEDIN);
                resolve(data);
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    linkedinLogin(linkedinLoginResponse: LinkedinLoginCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        linkedinLoginResponse.username = !!linkedinLoginResponse && !!linkedinLoginResponse.username ? linkedinLoginResponse.username.trim() : null;
        linkedinLoginResponse = this.prepare(linkedinLoginResponse);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.linkedinLogin, linkedinLoginResponse, { headers: headers }).subscribe(data => {
                this.user = data;
                this.logIntoBackgroundServices(this.user);
                this.events.publish(EventKey.USER_LOGGEDIN);
                resolve(data);
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    bookingsuiteLogin(bookingsuiteLoginResponse: BookingsuiteLoginCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        bookingsuiteLoginResponse.username = !!bookingsuiteLoginResponse && !!bookingsuiteLoginResponse.username ? bookingsuiteLoginResponse.username.trim() : null;
        bookingsuiteLoginResponse = this.prepare(bookingsuiteLoginResponse);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.bookingsuiteLogin, bookingsuiteLoginResponse, { headers: headers }).subscribe(data => {
                this.user = data;
                this.logIntoBackgroundServices(this.user);
                this.events.publish(EventKey.USER_LOGGEDIN);
                resolve(data);
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    apaleoLogin(apaleoLoginResponse: ApaleoLoginCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        apaleoLoginResponse.username = !!apaleoLoginResponse && !!apaleoLoginResponse.username ? apaleoLoginResponse.username.trim() : null;
        apaleoLoginResponse = this.prepare(apaleoLoginResponse);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.apaleoLogin, apaleoLoginResponse, { headers: headers }).subscribe(data => {
                this.user = data;
                this.logIntoBackgroundServices(this.user);
                this.events.publish(EventKey.USER_LOGGEDIN);
                resolve(data);
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    logout(logoutData?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const _logout: Function = () => {
                this.http.post('/api/' + this.authOptions.urls.logout, logoutData).subscribe(data => {
                    this.user = null;
                    resolve(data);
                }, err => {
                    this.user = null;
                    reject(err);
                });
            };

            this.pushNotificationService.isEnabled().then(pushNotificationsEnabled => {
                if (pushNotificationsEnabled) {
                    var subscription = this.events.subscribe(EventKey.FCMTOKEN_DELETED, () => {
                        // console.log("Logout Event received from pushFcmProvider");
                        _logout();
                        subscription.unsubscribe();
                    });
                    this.logOutOfBackgroundServices(this.user);
                } else {
                    // console.log("Normal logout without pushFCM");
                    _logout();
                }
            });
        });
    }

    signup(signup: SignupCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        signup.username = !!signup && !!signup.username ? signup.username.trim() : null;
        signup = this.prepare(signup);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.signup, signup, { headers: headers }).subscribe(data => {
                this.user = data;
                this.logIntoBackgroundServices(this.user);
                resolve(data);
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    // logged-out, i.e. "forgot password"
    passwordReset(passwordReset: PasswordResetCredentials): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        passwordReset.username = !!passwordReset && !!passwordReset.username ? passwordReset.username.trim() : null;
        passwordReset = this.prepare(passwordReset);
        return new Promise((resolve, reject) => {
            this.http.post('/api/' + this.authOptions.urls.passwordReset, passwordReset, { headers: headers }).subscribe(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    // logged-out, i.e. "change my password" after "forgot password"
    passwordResetChange(passwordResetChange: PasswordResetChangeCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        passwordResetChange = this.prepare(passwordResetChange);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.passwordResetChange, passwordResetChange, { headers: headers }).subscribe(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    // logged-in, i.e. "change my password"
    passwordChange(passwordChange: PasswordChangeCredentials): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        passwordChange = this.prepare(passwordChange);
        return new Promise((resolve, reject) => {
            this.http.post('/api/' + this.authOptions.urls.passwordChange, passwordChange, { headers: headers }).subscribe(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    sessionUser(): Promise<AuthResponse> {
        /*let headers = new HttpHeaders();
        headers = headers.set('Content-Type',this.authOptions.encodeAs);*/
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.get<AuthResponse>('/api/' + this.authOptions.urls.sessionUser /*, {headers: headers}*/).subscribe(data => {
                if (data) {
                    this.user = data;
                    resolve(data);
                } else {
                    this.user = null;
                    reject(data);
                }
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    issueError(err: AuthError, revoke: boolean = false, revalidate: boolean = false): void {
        if (revoke && this.user) {
            if (revalidate) {
                this.sessionUser().then(res => {
                    console.log('not logged out, after succeeded to revalidate session');
                }, err => {
                    console.log('logged out, after failed to revalidate session');
                });
            } else {
                console.log('revoking session');
                this.user = null;
            }
        }
        this.errorSubject.next(err);
    }

    private logIntoBackgroundServices(user: AuthResponse): void {
        this.pushNotificationService.isEnabled().then(pushNotificationsEnabled => {
            if (pushNotificationsEnabled) {
                this.pushNotificationService.createTokenForUser(user);
            }
        });
    }

    private logOutOfBackgroundServices(user: AuthResponse): void {
        this.pushNotificationService.isEnabled().then(pushNotificationsEnabled => {
            if (pushNotificationsEnabled) {
                this.pushNotificationService.deleteTokenForUser(user);
            }
        });
    }
}
