import { Pipe, PipeTransform } from '@angular/core';

/**
 * Replaces placeholders with the values of the given array.
 * Example:
 * value = "This is {0} and it was {1}"
 * params = ["a test", "successful"]
 * transforms to: "This is a test and it was successful"
 */
@Pipe({
    name: 'withParams'
})
export class WithParamsPipe implements PipeTransform {
    transform(value: string, params: any[] = []): string {
        if (!value || !params || params.length === 0) {
            return value;
        }
        return value.replace(/{(\d+)}/g, (match, index) => {
            return params[index] !== undefined ? params[index] : match;
        });
    }
}
