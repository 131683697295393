import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataGroup, DataPage, MetaFile, MetaId } from 'metagroup';
import { BaseList } from '../baselist/baselist';
import { CometchatService } from './cometchat.service';

export class ChatPerson {
  id: number;
  coachId?: MetaId; // reference to a coach if person is a coach (currently, otherwise person is the app user...) (type of a coach is DataPage)
  name: string;
  image: MetaFile;
}

export class Nachricht {
  id: number;
  chatId: number;
  from: ChatPerson; // author of message
  to: ChatPerson; // recipient of message
  time: number; // unix epoch timestamp
  text: string;
}

export class Chat {
  id: number;
  to: ChatPerson; // person current user is chatting to in this chat
  last: Nachricht;
}

@Injectable({
  providedIn: 'root'
})
export class NachrichtenProvider {

  constructor(
    public http: HttpClient,
    public cometchatProvider: CometchatService,
    public translateService: TranslateService,
  ) {
  }

  /**
   * returns list of all chats of the current user
   * @returns {Promise<BaseList<Chat>>}
   */
  getChatList(): Promise<BaseList<Chat>> {
    return new Promise<BaseList<Chat>>((resolve, reject) => {
      this.http.get<BaseList<Chat>>('/api/chat/list').subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * returns chat specified by given chatId
   * errors e.g. if chat is not accessible to user
   * @param {number} id
   * @returns {Promise<Chat>}
   */
  getChat(id: number): Promise<Chat> {
    return new Promise<Chat>((resolve, reject) => {
      this.http.get<Chat>('/api/chatdata/' + id).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * returns chat of current user to a given coach
   * errors if the current user has no chat to that coach
   * @param {DataPage} coach
   * @returns {Promise<Chat>}
   */
  getChatToCoach(coach: DataPage): Promise<Chat> {
    return new Promise<Chat>((resolve, reject) => {
      this.http.get<Chat>('/api/coach/' + coach.id + '/chatdata').subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * creates and returns a chat of current user to a given coach
   * errors in case the current user already has a chat to the given coach
   * @param {DataPage} coach
   * @returns {Promise<Chat>}
   */
  createChatToCoach(coach: DataPage): Promise<Chat> {
    let chat = new Chat();
    chat.to = new ChatPerson();
    chat.to.coachId = coach.id;
    chat.to.name = <string>(<DataGroup>coach.person).fieldData.name; //FIXME: chat.to.name = has to be already in body / later will be added by backend
    chat.to.image = <MetaFile>(<DataGroup>coach.person).fieldData.foto; //FIXME: chat.to.image = has to be already in body / later will be added by backend
    return new Promise<Chat>((resolve, reject) => {
      this.http.post<Chat>('/api/coach/' + chat.to.coachId + '/chat/add', chat).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * creates and returns a chat of current user to a given coach
   * errors in case the current user already has a chat to the given coach
   * @param {DataPage} coach
   * @returns {Promise<any>} - data are ChatPage data or error
   */
  initializeChat(coach: DataPage): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (coach && coach.id && coach.userid) {
        const chatPageData = { userId: coach.userid };

        // this.cometchatProvider.getConversation('' + coach.userid).then(
        //   conversation => {
        //     resolve(chatPageData);
        //   },
        //   error => {
        //     const sendInitial = () => {
        //       return this.sendMessageFromUser('' + coach.userid, this.translateService.instant('chat.initialmessage')).then(
        //         message => {
        //           resolve(chatPageData);
        //         },
        //         error => {
        //           console.error(error);
        //         }
        //       );
        //     };
        //     this.cometchatProvider.getUser('' + coach.userid).then(
        //       user => {
        //         sendInitial();
        //       },
        //       error => {
        //         console.error(error);
        //       }
        //     )
        //   }
        // );
      } else {
        console.error('cannot contact coach, coach missing');
      }
    });
  }

  /**
   * sends message as another user. Messages send with current user: cometchatProvider.sendMessage
   * 
   * @param {string} senderId
   * @param {string} message
   * @returns {Promise<BaseList<Object>>}
   */
  sendMessageFromUser(senderId: string, message: string): Promise<BaseList<Object>> {
    return new Promise<BaseList<Object>>((resolve, reject) => {
      this.http.post<BaseList<Object>>('/api/chat/send/fromuser', {
        senderId: senderId,
        message: message
      }).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * returns list of all messages in a chat specified by chatId
   * errors e.g. if chat is not accessible to user
   * @param {string} chatId
   * @returns {Promise<BaseList<Nachricht>>}
   */
  getList(chatId: number): Promise<BaseList<Nachricht>> {
    return new Promise<BaseList<Nachricht>>((resolve, reject) => {
      this.http.get<BaseList<Nachricht>>('/api/chat/' + chatId + '/nachricht/list').subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * returns message specified by given id
   * errors e.g. if message is not accessible to user
   * @param {number} id
   * @returns {Promise<Nachricht>}
   */
  get(id: number): Promise<Nachricht> {
    return new Promise<Nachricht>((resolve, reject) => {
      this.http.get<Nachricht>('/api/nachrichtdata/' + id).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * creates and returns a new message in chat specified by the chatId in the message
   * errors if nachricht.chatId is invalid (no such chat exists for the current user)
   * @param {Nachricht} nachricht
   * @returns {Promise<Nachricht>}
   */
  post(nachricht: Nachricht): Promise<Nachricht> {
    return new Promise<Nachricht>((resolve, reject) => {
      this.http.post<Nachricht>('/api/nachricht/add', nachricht).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

}
