import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class PublicGuard {

    constructor(
        public authProvider: AuthService,
        public router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.authProvider.user) {
            console.log('PUBLIC-GUARD: not logged in');
            // User is not logged in, allow access to the public route
            return true;
        }

        // User is logged in, redirecting to the appropriate start page
        console.log('PUBLIC-GUARD: logged in, redirecting to start page');
        this.router.navigate(['/']);
        return false;
    }
}
