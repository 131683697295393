import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links';
import { ConstantVariable } from 'constant-variable';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'services/auth/auth.service';
import { PlatformService } from 'services/platform/platform.service';
import { StorageKey } from 'services/storage/storage.keys';
import { StorageService } from 'services/storage/storage.service';

export class BranchConfig {
  apiBaseUrl: string;
  companyName?: string;
  userId?: number;
  welcomeVideoUri: string;
  colorMap?: Map<string, string>;
  logo?: string;
  features: {
    changeWillingness: boolean;
    counters: boolean;
    tokens: boolean;
    registration: boolean;
    dashboard: boolean;
    onboarding: boolean;
    account: boolean;
    notificationsettings: boolean;
    paidgoods: boolean;
    bewerbungsmappe: boolean;
    jobboerse: boolean;
    applications: boolean;
    recherche: boolean;
    termine: boolean;
    bewerbungstipps: boolean;
    bewerbungsservice: boolean;
    nachrichten: boolean;
    impressum: boolean;
    terms: boolean;
    contact: boolean;
    smartphoneConnect: boolean;
    showLogo: boolean;
    pushNotifications: boolean;
  }

}

// for devevelopment / testing only
const AllAllowedBranchConfig: BranchConfig = {
  apiBaseUrl: 'http://localhost:8080/bms/', // URL for development only
  welcomeVideoUri: 'https://youtu.be/n6Gkidr6fJg',
  colorMap: new Map<string, string>([
    ['cdz-primary-color', '#fb7900'],
    ['cdz-primary-color-contrast', '#ffffff'],
    ['cdz-secondary-color', '#1a1a1a'],
    ['cdz-secondary-color-contrast', '#fb7900'],
    ['cdz-menu-color', '#eeeeee'],
    ['cdz-background-color', '#ffffff']
  ]),
  logo: '',
  features: {
    changeWillingness: true,
    counters: true,
    tokens: true,
    registration: true,
    dashboard: true,
    onboarding: true,
    account: true,
    notificationsettings: true,
    paidgoods: true,
    bewerbungsmappe: true,
    jobboerse: true,
    applications: true,
    recherche: true,
    termine: true,
    bewerbungstipps: true,
    bewerbungsservice: true,
    nachrichten: true,
    impressum: true,
    terms: true,
    contact: true,
    smartphoneConnect: true,
    showLogo: true,
    pushNotifications: true
  }
};

// used as fallback, see below; everything configurable denied
const AllDeniedBranchConfig: BranchConfig = {
  apiBaseUrl: null,
  welcomeVideoUri: '',
  colorMap: null,
  logo: '',
  features: {
    changeWillingness: false,
    counters: false,
    tokens: false,
    registration: false,
    dashboard: false,
    onboarding: false,
    account: false,
    notificationsettings: false,
    paidgoods: false,
    bewerbungsmappe: false,
    jobboerse: false,
    applications: false,
    recherche: false,
    termine: false,
    bewerbungstipps: false,
    bewerbungsservice: false,
    nachrichten: false,
    impressum: false,
    terms: false,
    contact: false,
    smartphoneConnect: false,
    showLogo: false,
    pushNotifications: false
  }
};

// configuration that will be user when branch.io does not respond with a config
// and no previously loaded config is available, e.g. due to network down, etc.pp.
export const DefaultBranchConfig: BranchConfig = AllDeniedBranchConfig;

export class BranchData {
  data: string;
  data_parsed?: {
    "$desktop_url"?: string;
    "$one_time_use"?: boolean;
    "+click_timestamp"?: number;
    "+clicked_branch_link": boolean;
    "+is_first_session": boolean;
    "+match_guaranteed"?: boolean;
    config_url?: string; // <-- this is the important thing
    "~creation_source"?: number;
    "~id"?: string;
    "~referring_link"?: string;
    "~channel"?: string; // "bms"
    "~feature"?: string; // "onboarding"
  };
  has_app: null; //?
  identity: null; //?
  referring_identity: null; //?
  referring_link: string;
}

@Injectable({
  providedIn: 'root'
})
export class BranchConfigService {

  public config: BranchConfig;
  public configuration: Promise<BranchConfig> = null;
  public isReady: Promise<any>;
  public _configChanged: BehaviorSubject<BranchConfig>;
  public configChanged: Observable<BranchConfig>;
  private resolveIsReady: any;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private storageService: StorageService,
    private platformService: PlatformService
  ) {
    this._configChanged = new BehaviorSubject(null);
    this.configChanged = this._configChanged.asObservable();
    this.isReady = new Promise((resolve, reject) => {
      this.resolveIsReady = resolve;
    });
  }

  public init(): Promise<BranchConfig> {
    this.configuration = null;
    this.config = null;
    const browser = (<any>window).branch;
    if (this.platformService.isNative()) {
      // load branch.io config for native devices if possible
      this.configuration = this.configureNative();
      console.info("Using branch.io NATIVE settings.");
    } else if (browser) {
      // load branch.io config for browser if possible
      this.configuration = this.configureBrowser(browser);
      console.info("Using branch.io BROWSER settings.");
    } else {
      console.error('Error: window.branch is not present');
      this.configuration = Promise.reject('branch_io_error');
    }

    this.configuration.then((config: BranchConfig) => {
      console.log('BranchConfigProvider successfully got a configuration:', config);
      this.config = config;
      ConstantVariable.apiBaseUrl = config.apiBaseUrl;
      this._configChanged.next(this.config);
      this.resolveIsReady(this.config);
    }, error => {
      console.error('BranchConfigProvider error: ', error);
      this.config = this.config || DefaultBranchConfig;
      this._configChanged.next(this.config);
      this.resolveIsReady(this.config);
    });

    // reload config after user change
    this.auth.userChanged().subscribe(event => {
      console.log('BranchConfigProvider, checking user config');
      this.reloadForUser().then((config: BranchConfig) => console.log('BranchConfigProvider, got user config', config));
    });

    return this.configuration;
  }

  /**
   * lädt die conf über "/api/config" neu mit userdaten
   */
  public reloadForUser(): Promise<BranchConfig> {
    if (this.config) {
      return new Promise<BranchConfig>((resolve, reject) => {
        this.getconfigure('/api/config').subscribe(config => {

          this.putpermanent(config);
          this.config = config;
          this._configChanged.next(this.config);
          this.resolveIsReady(this.config);

          resolve(config);
        }, error => {
          this.getpermanent().then(pconfig => {
            resolve(pconfig);
          }, error => {
            reject('branch_io_error');
          });
        });
      });
    } else {
      return Promise.resolve(null);
    }
  }

  /**
   * Load data from branch.io for native apps.
   */
  private configureNative(): Promise<BranchConfig> {
    return new Promise<BranchConfig>((resolve, reject) => {
      BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
        // load branch.io data
        console.debug('Branch init successful: ', event.referringParams);
        this.configure(event.referringParams, resolve, reject);
      });
    });
  }

  /**
   * Load data from branch.io for browser.
   */
  private configureBrowser(branch: any): Promise<BranchConfig> {
    return new Promise<BranchConfig>((resolve, reject) => {
      // load branch.io data
      branch.data((err, data: BranchData) => {
        this.configure(data ? data.data_parsed : null, resolve, reject);
      });
    });
  }

  configure(data: any, resolve: any, reject: any): void {

    // for testing:
    //data.config_url = 'http://192.168.0.194:8080/bms/rest/api/candooze/mapping/v1/anonym/config';
    //

    let loadPermanent = () => {
      // reload config
      this.getpermanenturl().then(configUrl => {
        this.getconfigure(configUrl).subscribe(config => {
          this.putpermanent(config);
          resolve(config);
        }, error => {
          this.getpermanent().then(pconfig => {
            resolve(pconfig);
          }, error => {
            reject('branch_io_error');
          });
        });
      }, error => {
        this.getpermanent().then(pconfig => {
          resolve(pconfig);
        }, error => {
          reject('branch_io_error');
        });
      });
    }; // end load default

    if (data && data.config_url) {
      // neue branch.io aufruf
      this.putpermanenturl(data.config_url);
      this.getconfigure(data.config_url).subscribe(config => {
        this.putpermanent(config);
        resolve(config);
      }, error => {
        this.getpermanent().then(pconfig => {
          resolve(pconfig);
        }, error => {
          reject('branch_io_error');
        });
      });

    } else if (!!this.auth.user) {
      // load with existing user use permanent
      this.getpermanent().then(pconfig => {
        resolve(pconfig);
      }, error => {
        loadPermanent();
      })

    } else {
      loadPermanent();
    }
  }

  getconfigure(configUrl: string) {
    return this.http.get<BranchConfig>(configUrl);
  }

  putpermanent(config: BranchConfig): Promise<void> {
    return this.storageService.set(StorageKey.BRANCH_CONFIG, config);
  }

  async getpermanent(): Promise<BranchConfig> {
    return this.storageService.get(StorageKey.BRANCH_CONFIG);
  }

  putpermanenturl(configUrl: string): Promise<void> {
    return this.storageService.set(StorageKey.BRANCH_CONFIG_URL, configUrl);
  }

  async getpermanenturl(): Promise<string> {
    return this.storageService.get(StorageKey.BRANCH_CONFIG_URL);
  }
}
