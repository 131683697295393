import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'services/auth/auth.service';

/**
 * @deprecated use User instead
 */
export class Userdata {
  imgUrl: string;
  job: string;
  firstName: string;
  name: string;
  percentage: number | null; //TODO: i assume this will be a number (just getting a null for now)
  place: string | null; //TODO: i guess this will be a plain string (not an address object, just getting a null for now)
  isFirstLogin: boolean | null;
  isEmployed: boolean | null;
  gender: string | null; // "male"|"female"                         //TODO: not yet implemented in the backend
  openinvitations: number | null;                                   //TODO: not yet implemented in the backend
  openapplications: number | null;                                  //TODO: not yet implemented in the backend
  newtips: number | null;                                           //TODO: not yet implemented in the backend
  progress: number | null;                                          //TODO: not yet implemented in the backend
  progressneeded: number | null;                                    //TODO: not yet implemented in the backend
  karrierestatus: string | null; // Übersetzung des Karrierestatus
  coachId: number | null; // persönlicher zugewiesener coach
}

/**
 * @deprecated use UserService instead
 */
@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  userdata: Userdata = new Userdata();
  userdatastale: boolean = true;

  constructor(
    public http: HttpClient,
  ) { }

  flush() {
    this.userdatastale = true;
    let props = Object.getOwnPropertyNames(this.userdata);
    props.forEach(prop => {
      delete this.userdata[prop];
    });
    this.get().then(res => {
      console.log('userdata refreshed');
    }, error => {
      console.error('userdata not refreshed', error);
    });
  }

  refresh() {
    this.userdatastale = true;
    this.get().then(res => {
      console.log('userdata refreshed');
    }, error => {
      console.error('userdata not refreshed', error);
    });
  }

  get(): Promise<Userdata> {
    return new Promise<Userdata>((resolve, reject) => {
      if (!this.userdatastale) {
        resolve(this.userdata);
      } else {
        this.http.get<Userdata>('/api/user/userdata').subscribe(data => {
          this.userdatastale = false;
          //this.userdata = data;
          let propsr = Object.getOwnPropertyNames(this.userdata);
          propsr.forEach(prop => {
            delete this.userdata[prop];
          });
          let propsn = Object.getOwnPropertyNames(data);
          propsn.forEach(prop => {
            this.userdata[prop] = data[prop];
          });

          resolve(this.userdata);
        }, err => {
          reject(err);
        });
      }
    });
  }

  reset(type: string): Promise<Userdata> {
    return new Promise<Userdata>((resolve, reject) => {
      this.http.post<Userdata>('/api/user/userdatareset', { type: type }).subscribe(data => {
        delete this.userdata[type];
        resolve(data);
      }, err => {
        delete this.userdata[type];
        reject(err);
      });
    });
  }

}
