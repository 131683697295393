import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ConstantVariable } from 'constant-variable';
import { AuthService } from 'services/auth/auth.service';
import { BranchConfig, BranchConfigService } from 'services/branch-config/branch-config';
import { EventKey } from 'services/events/events.keys';
import { EventsService } from 'services/events/events.service';
import { CometchatService } from 'services/nachrichten/cometchat.service';
import { RoutingService } from 'services/routing/routing.service';
import { Userdata, UserdataService } from 'services/userdata/userdata.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() cdzMobile?: boolean;

  backPath: string;

  showAllFeatures: boolean;
  showCompanyName: boolean;
  showHeaderButtons: boolean;
  showNachrichten: boolean;
  showBewerbungen: boolean;
  showTermine: boolean;
  showBewerbungstipps: boolean;
  showAccount: boolean;
  showKontakt: boolean;
  showChangeWillingness: boolean;

  companyName: string;
  logoUrl: string;

  userdata: Userdata;
  unreadMessagesCount: number = 0;

  constructor(
    private configProvider: BranchConfigService,
    private routingService: RoutingService,
    private navController: NavController,
    private userdataProvider: UserdataService,
    private cometchatProvider: CometchatService,
    private events: EventsService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.configProvider.configChanged.subscribe((config: BranchConfig) => {
      this.showAllFeatures = ConstantVariable.showAllFeatures;
      this.showCompanyName = !!config?.companyName && !config?.features?.showLogo;
      this.showHeaderButtons = config?.features?.counters || this.showAllFeatures;
      this.showNachrichten = config?.features?.nachrichten || this.showAllFeatures;
      this.showBewerbungen = config?.features?.applications || this.showAllFeatures;
      this.showTermine = config?.features?.termine || this.showAllFeatures;
      this.showBewerbungstipps = config?.features?.bewerbungstipps || this.showAllFeatures;
      this.showAccount = config?.features?.account || this.showAllFeatures;
      this.showKontakt = config?.features?.contact || this.showAllFeatures;
      this.showChangeWillingness = config?.features?.changeWillingness || this.showAllFeatures;
      this.logoUrl = config?.logo;

      this.companyName = config?.companyName;
    });

    this.routingService.getBackUrlObservable().subscribe(url => this.backPath = url);


    this.userdataProvider.get()
      .then(data => this.userdata = data)
      .catch(error => console.error('failed to load userdata', error));

    this.refreshUnreadMessagesCount();
    this.events.subscribe(EventKey.COMETCHAT_MESSAGECOUNT, () => {
      this.refreshUnreadMessagesCount();
    });
  }

  activatePage(page) {
    this.routingService.resetActive(page);
  }

  openPage(page: string, params?: any) {
    this.navController.navigateRoot(this.routingService.getPathReplaceAny(page, params));
    this.activatePage(page);
  }

  logout() {
    this.auth.logout();
  }

  private refreshUnreadMessagesCount(): void {
    this.cometchatProvider.getUnreadMessagesCount()
      .then(data => this.unreadMessagesCount = data)
      .catch(error => console.error('failed to load unreadMessagesCount from cometchat', error));
  }

  navigateBack() {
    this.routingService.goBack();
  }
}
