import { AuthService } from './auth.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        public authProvider: AuthService,
        public router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!!this.authProvider.user) {
            console.log('AUTH-GUARD: logged in');
            // authorised so return true
            return true;
        }
        
        // not logged in, so store redirect url and go to login page
        console.log('AUTH-GUARD: not logged in, redirecting to login page');
        this.authProvider.loginRedirect = state.url || '/';
        this.router.navigate(['/login']);
        return false;
    }
}
