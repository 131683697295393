import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PaidGoodsService } from "services/paid-goods/paid-goods.service";
import { ProductType } from 'services/product/product-type';
import { DataFields, MetaField } from 'src/app/metagroup';

@Component({
  selector: 'page-leistung-view-more-modal',
  templateUrl: 'leistung-view-more-modal.html',
  styleUrls: ['leistung-view-more-modal.scss']
})
export class LeistungViewMoreModalPage implements OnInit {
  public readonly pageName: string = "LeistungViewMoreModalPage";

 @Input() leistungen: DataFields[];
 @Input() leistung: DataFields;
  viewIndex: number = null;

  constructor(
   public injector: Injector,
   public paidGoodsProvider: PaidGoodsService,
   private modalController: ModalController
  )
 {
}

ngOnInit() {
  console.log('ngOnInit LeistungViewMoreModalPage');
  this.viewIndex = this.leistungen.findIndex(leistung => leistung.id === this.leistung.id);
  if (this.viewIndex === -1) {
    this.viewIndex = 0;
  }
  }

  view(index: number) {
    this.viewIndex = index;
  }

  prev() {
    this.viewIndex--;
    if (this.viewIndex < 0) {
      this.viewIndex = this.leistungen.length - 1;
    }
  }
  next() {
    this.viewIndex++;
    if (this.viewIndex >= this.leistungen.length) {
      this.viewIndex = 0;
    }
  }

  getBuyUrl() {
    return this.paidGoodsProvider.buildPaymentUrl(ProductType.COACHING, Number(this.leistungen[this.viewIndex].id));
  }

  cancel() {
    this.dismiss();
  }

  dismiss(leistung?: MetaField) {
    this.modalController.dismiss(leistung);
  }
}
