/**
 * Predefined product types that have to match the CdzContentTypeDto in the backend
 */
export enum ProductType {
    WEBINAR = 'WEBINAR',
    PODCAST = 'PODCAST',
    VIDEO = 'VIDEO',
    ADVICE = 'ADVICE',
    COACHING = 'COACHING',
    SERVICE = 'SERVICE',
    COURSE = 'COURSE'
}