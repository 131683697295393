// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-standalone-content {
  --padding-start: 0px;
  --padding-end: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/chat-standalone/chat-standalone.page.scss"],"names":[],"mappings":"AAAA;EAEI,oBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".chat-standalone-content {\n    // --padding-top: 0px;\n    --padding-start: 0px;\n    --padding-end: 0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
