export enum StorageKey {
    /** Collapsed state of the apps menu */
    MENU_COLLAPSED = 'cdz.storage.menu.collapsed',
    /** Most recent branch condig */
    BRANCH_CONFIG = 'cdz.storage.branch.config',
    /** Most recent branch condig url */
    BRANCH_CONFIG_URL = 'cdz.storage.branch.config.url',
    /** Auth access token */
    AUTH_ACCESSTOKEN = 'cdz.auth.accessToken',
    /** Auth response (indicates if a user is logged in or not) */
    AUTH_RESPONSE = 'cdz.auth.response',
}