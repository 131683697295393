// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button {
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.header-cdz-desktop {
  display: flex;
}

.header-logo {
  max-height: 55px;
  vertical-align: middle;
}

.header-buttons {
  margin-right: 20px;
}
.header-buttons .button.icon-button {
  font-size: 20px;
}

.header-user-profile {
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.header-user-profile .header-user-image {
  background-size: 50px;
  background-position-x: 50%;
  background-position-y: 50%;
}

.popover-button {
  --inner-border-width: 0px;
}

.header-cdz-mobile .header-title-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 20px;
}
.header-cdz-mobile .header-buttons {
  margin-right: 0;
  float: right;
}

@media (max-width: 767px) {
  .status,
  .header-logo {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,iBAAA;EACA,eAAA;AADJ;;AAIA;EACI,aAAA;AADJ;;AAIA;EACI,gBAAA;EACA,sBAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;AAGI;EACI,eAAA;AADR;;AAKA;EACI,YAAA;EACA,WAAA;EACA,eAAA;AAFJ;AAII;EACI,qBAAA;EACA,0BAAA;EACA,0BAAA;AAFR;;AAMA;EACI,yBAAA;AAHJ;;AAOI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;AAJR;AAOI;EACI,eAAA;EACA,YAAA;AALR;;AASA;EAEI;;IAEI,aAAA;EAPN;AACF","sourcesContent":["@use 'globals' as g;\n\n.back-button {\n    font-size: 20px;\n    margin-left: 10px;\n    cursor: pointer;\n}\n\n.header-cdz-desktop {\n    display: flex;\n}\n\n.header-logo {\n    max-height: 55px;\n    vertical-align: middle;\n}\n\n.header-buttons {\n    margin-right: 20px;\n\n    .button.icon-button {\n        font-size: 20px;\n    }\n}\n\n.header-user-profile {\n    height: 50px;\n    width: 50px;\n    cursor: pointer;\n\n    .header-user-image {\n        background-size: 50px;\n        background-position-x: 50%;\n        background-position-y: 50%;\n    }\n}\n\n.popover-button {\n    --inner-border-width: 0px;\n}\n\n.header-cdz-mobile {\n    .header-title-text {\n        position: absolute;\n        top: 0;\n        left: 0;\n        right: 0;\n        bottom: 0;\n        margin: auto;\n        max-height: 20px;\n    }\n\n    .header-buttons {\n        margin-right: 0;\n        float: right;\n    }\n}\n\n@media (max-width: g.$cdz-mobilemax) {\n\n    .status,\n    .header-logo {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
