import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ContactService } from 'services/contact/contact.service';
import { RoutingService } from 'services/routing/routing.service';
import { ToastService } from 'services/toast/toast.service';
import { ConstantRoute } from 'src/app/constant-routes';

@Component({
  selector: 'page-contact',
  templateUrl: 'contact.page.html',
  styleUrls: ['contact.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
  ]
})
export class ContactPage implements OnInit {
  subjectKey: string = 'subject';
  messageKey: string = 'message';

  contactForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private contactService: ContactService,
    private toastService: ToastService,
    private routingService: RoutingService
  ) { }

  ngOnInit() {
    this.routingService.setDefaultBackUrl(ConstantRoute.FULL_PROFILE);
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
  }

  send() {
    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      return;
    }

    this.contactService.create(this.contactForm.value).then(data => {
      this.toastService.handleSuccessTranslate('page.contact.confirmation');
      this.routingService.goBack();
    });
  }
}
