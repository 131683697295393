import { NgModule } from '@angular/core';
import { DownloadPipe } from './download/download.pipe';
import { DurationPipe } from './duration/duration.pipe';
import { HighlightPipe } from './highlight/highlight.pipe';
import { ImagePipe } from './image/image.pipe';
import { TrustResourceUrlPipe } from './trust-resource-url/trust-resource-url.pipe';
import { UnsafeKeepHtmlPipe } from './unsafe-keep-html/unsafe-keep-html.pipe';
import { WithParamsPipe } from './with-params/with-params.pipe';

@NgModule({
	declarations: [
		DurationPipe,
		TrustResourceUrlPipe,
		ImagePipe,
		UnsafeKeepHtmlPipe,
		DownloadPipe,
		HighlightPipe,
		WithParamsPipe
	],
	imports: [],
	exports: [
		DurationPipe,
		TrustResourceUrlPipe,
		ImagePipe,
		UnsafeKeepHtmlPipe,
		DownloadPipe,
		HighlightPipe,
		WithParamsPipe
	]
})
export class PipesModule { }
