import { CometchatService } from 'services/nachrichten/cometchat.service';
import { Component, AfterViewInit, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
//import { Subject } from 'rxjs';

import { ConstantVariable } from 'constant-variable';
import { BranchConfigService } from "services/branch-config/branch-config";
import { Pages } from 'src/app/pages';
import { AuthService } from 'services/auth/auth.service';
import { MessageService } from 'services/message/message.service';
import { NavController } from '@ionic/angular';
import { RoutingService } from 'services/routing/routing.service';
import $ from 'jquery';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'wrap-content',
  templateUrl: 'wrap-content.html',
  styleUrls: ['wrap-content.scss']
})
export class WrapContentComponent implements AfterViewInit, OnDestroy {
  @ViewChild('menuRootElement') menuRootElement;
  @ViewChildren("lazySubMenuItem") lazySubMenuItems: QueryList<ElementRef>;
  pages:any;
  crumbs:any[];
  isLogin:boolean;
  //errorSubscription: Subscription;
  userSubscription: Subscription;
  stateSubscription: Subscription;
  navSubscription: Subscription;
  showAllFeatures: boolean = false;

  constructor(
    private router: Router,
    public auth: AuthService,
    public configProvider: BranchConfigService,
    public message: MessageService,
    public navController: NavController,
    public routingService: RoutingService,
    private cometchatProvider: CometchatService
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;

    this.pages = Pages;

    this.updateLoginState(!!this.auth.user);

    this.userSubscription = this.auth.userChanged().subscribe(user => {
      console.log('WRC: userSubscription got user:', user);
      this.updateLoginState(!!user); //TODO: necessary?
    });
    this.stateSubscription = this.auth.stateChanged().subscribe(isLoggedIn => {
      console.log('WRC: stateSubscription got changed state:', isLoggedIn);
      this.updateLoginState(isLoggedIn);
    });
  }
  
  ngAfterViewInit() {
    // create an observer, that sets the.highlight-class for the top-menu, if one sub menu is highlighted
    let observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        // check for a highlighted sub-menu, then get the previous element (which is the top-menu) and highlight it too
        let subMenu = $('.sub-menu').has('.highlight');
        subMenu.prev().addClass('highlight');
      });
    });
    // observe all sub-menu-items for changes in their class
    // .highlight will be set and removed by ionic depending of the active route
    $('.sub-menu-item').each(function(i, item) {
      observer.observe($(this)[0], { attributes: true, attributeFilter: ['class'] });
    });

    // for the first load
    this.lazySubMenuItems.changes.subscribe(() => {
      // check for a highlighted sub-menu, then get the previous element (which is the top-menu) and highlight it too
      let subMenu = $('.sub-menu').has('.highlight');
      subMenu.prev().addClass('highlight');
    });

    // CometChat Widget disabled
    // this.configProvider.configuration.then(config => {
    //   if (config.features.nachrichten) {
    //     // wait for branch.io to return the api config, so we can request cometChatData from the backend
    //     this.cometchatProvider.getCometchatData().then(() => {
    //       // then launch the cometchat widget
    //       this.cometchatProvider.launchWidget();
    //     });
    //   }
    // });
  }

  ngOnDestroy() {
    if(this.navSubscription) this.navSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.stateSubscription.unsubscribe();
  }

  openPage(page, root = true) {
    this.routingService.resetActive(page);
    // navigate to the new page if it is not the current page
    if (root) {
      this.navController.navigateRoot(this.routingService.getPathReplaceAny(page.page, page.params));
    } else {
      this.navController.navigateForward(this.routingService.getPathReplaceAny(page.page, page.params));
    }
  }

  getRootPath(page) {
    return '/' + this.routingService.getPathReplaceAny(page.page, page.params);
  }

  activatePage(page) {
    this.routingService.resetActive(page);
  }

  /*
  doLogout() {
    //TODO: dont implement twice, invoke app.component doLogout method somehow (move to service?)
    this.auth.logout()
      .then(res => {
        console.log('WRC: logged out',res);
      },err=>{
        console.log('WRC: failed to log out',err);
      });
  }
  */

  updateLoginState(state: boolean) {
    console.log('WRC: state',state);
    this.isLogin = state;
  }
}
