import { BehaviorSubject, from, Observable, throwError } from 'rxjs';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { RuntimeConfigService } from '../runtime-config/runtime-config.service';
import { ApiAuthOptions, AuthOptions } from './auth-config';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        @Optional() @Inject(ApiAuthOptions) private authOptions: AuthOptions,
        private runtimeConfigProvider: RuntimeConfigService,
        private authService: AuthService
    ) {
        this.authOptions = AuthOptions.mergeAuthOptions(this.authOptions);
    }

    /**
     * Check if requests need to be authorized. If yes, authorize them.
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // sending the request only for the refresh token endpoint
        if (request.url.endsWith(this.authOptions.urls.login)) {
            return next.handle(request);
        }

        let authRegexp = (): RegExp => {
            return (this.runtimeConfigProvider && this.runtimeConfigProvider.get('apiAuthRegexp')) || /api/;
        };

        // test if the request has to be authorized
        if (this.authService.user && this.authService.user[this.authOptions.tokenProperty] && authRegexp().test(request.url)) {
            // maybe store this in localstorage later?
            // let token = this.storage.get(StorageKey.AUTH_ACCESSTOKEN);
            let token = this.authService.user ? this.authService.user[this.authOptions.tokenProperty] : '';

            return next.handle(this.addToken(request, token)).pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse && error.status === 401) {
                        // handle 401 UNAUTHORIZED
                        return this.handleUnauthorized(request, next);
                    } else {
                        return throwError(error);
                    }
                })
            ) as any;
        } else {
            return next.handle(request);
        }
    }

    /**
     * Adds the token to the request headers if it exists.
     * @param request the original request
     * @param token the token
     * @returns a new request with updated headers
     */
    private addToken(request: HttpRequest<any>, token: any) {
        let update: any = {};
        if (false == request.headers.has(this.authOptions.tokenHeader)) {
            update.headers = request.headers.set(this.authOptions.tokenHeader, this.authOptions.tokenPrefix + token);
        }
        if (false == this.authOptions.preventCookie) {
            update.withCredentials = true;
        }
        return request.clone(update);
    }

    /**
     * Handle 401 UNAUTHORIZED errors by requesting a new token.
     */
    private handleUnauthorized(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            console.debug('AUTH: refreshing token...');
            this.refreshTokenSubject.next(null);

            return from(this.authService.refresh()).pipe(
                switchMap((answer: any) => {
                    this.isRefreshing = false;
                    console.debug('AUTH: got new token: ', answer);
                    const token = answer[this.authOptions.tokenProperty];
                    this.refreshTokenSubject.next(token);
                    return next.handle(this.addToken(request, token));
                }));

        } else {
            console.debug('AUTH: already refreshing token, adding request to queue...');
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(request, token));
                }));
        }
    }
}