import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PaidGoodsService } from "services/paid-goods/paid-goods.service";
import { ProductType } from 'services/product/product-type';
import { Video } from "services/video/video.service";

@Component({
  selector: 'page-video-view-more-modal',
  templateUrl: 'video-view-more-modal.html',
  styleUrls: ['video-view-more-modal.scss']
})
export class VideoViewMoreModalPage implements OnInit {
  public readonly pageName: string = "VideoViewMoreModalPage";

  @Input() videos: Video[];
  @Input() video: Video;
  viewIndex: number = null;

  constructor(
    public injector: Injector,
    public paidGoodsProvider: PaidGoodsService,
    private modalController: ModalController
  ) {
  }
  
  ngOnInit() {
    console.log('ngOnInit VideoViewMoreModalPage');
    this.viewIndex = this.videos.findIndex(video => video.id === this.video.id);
    if(this.viewIndex === -1) {
      console.error('VideoViewMoreModalPage: video not in videos.');
      this.viewIndex = 0;
    }
  }

  view(index: number) {
    this.viewIndex = index;
  }
  prev() {
    this.viewIndex--;
    if(this.viewIndex < 0) {
      this.viewIndex = this.videos.length - 1;
    }
  }
  next() {
    this.viewIndex++;
    if(this.viewIndex >= this.videos.length) {
      this.viewIndex = 0;
    }
  }

  buynow() {
    return this.paidGoodsProvider.buildPaymentUrl(ProductType.VIDEO, this.videos[this.viewIndex].id);
  }

  cancel() {
    this.dismiss();
  }

  dismiss(video?: Video) {
    this.modalController.dismiss(video);
  }

}
