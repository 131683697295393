import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantVariable } from 'constant-variable';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'services/environment/environment.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (EnvironmentService.isMock() || EnvironmentService.isAnyMockUrlPresent()) {
            let mockUrl: string = EnvironmentService.getMockUrl(httpRequest.url);
            if (mockUrl && httpRequest.url !== mockUrl) {
                let contextPath = window.location.pathname.trim();
                contextPath = contextPath.endsWith("/") ? contextPath.slice(0, -1) : contextPath;
                mockUrl = contextPath + mockUrl;
                const oldApiUrl = httpRequest.url;
                httpRequest = httpRequest.clone({
                    url: mockUrl,
                    headers: httpRequest.headers.delete("Authorization"),
                    method: 'GET'
                });
                console.debug('MOCK: replaced url "' + oldApiUrl + '" with "' + mockUrl + '"');
            }
        }

        const apiUrl: string = this.replaceUrlForApi(httpRequest.url);
        if (apiUrl && httpRequest.url !== apiUrl) {
            httpRequest = httpRequest.clone({
                url: apiUrl
            });
            console.debug('API-CALL: calling "' + apiUrl + '"');
        }
        return next.handle(httpRequest);
    }

    private replaceUrlForApi(url: string): string {
        if (url && url.indexOf('/api/') === 0) {
            return url.replace('/api/', ConstantVariable.apiBaseUrl + '/');
        } else {
            return url;
        }
    }
}