import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PaidGoodsService } from "services/paid-goods/paid-goods.service";
import { ProductType } from 'services/product/product-type';
import { Webinar } from "services/webinar/webinar.service";

@Component({
  selector: 'page-webinar-view-more-modal',
  templateUrl: 'webinar-view-more-modal.html',
  styleUrls: ['webinar-view-more-modal.scss']
})
export class WebinarViewMoreModalPage implements OnInit {
  public readonly pageName: string = "WebinarViewMoreModalPage";

  @Input() webinars: Webinar[];
  @Input() webinar: Webinar;
  viewIndex: number = null;

  constructor(
    public injector: Injector,
    public paidGoodsProvider: PaidGoodsService,
    private modalController: ModalController
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit WebinarViewMoreModalPage');
    this.viewIndex = this.webinars.findIndex(webinar => webinar.id === this.webinar.id);
    if (this.viewIndex === -1) {
      console.error('WebinarViewMoreModalPage: webinar not in webinars.');
      this.viewIndex = 0;
    }
  }

  view(index: number) {
    this.viewIndex = index;
  }
  prev() {
    this.viewIndex--;
    if (this.viewIndex < 0) {
      this.viewIndex = this.webinars.length - 1;
    }
  }
  next() {
    this.viewIndex++;
    if (this.viewIndex >= this.webinars.length) {
      this.viewIndex = 0;
    }
  }

  buynow() {
    return this.paidGoodsProvider.buildPaymentUrl(ProductType.WEBINAR, this.webinars[this.viewIndex].id);
  }

  cancel() {
    this.dismiss();
  }

  dismiss(webinar?: Webinar) {
    this.modalController.dismiss(webinar);
  }

}
