import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, filter } from 'rxjs';
import { routes } from 'src/app/app-routing.module';
import { Pages } from 'src/app/pages';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class RoutingService {

    private history: Array<string> = new Array();
    private backUrlSubject: Subject<string> = new Subject<string>();

    constructor(
        private router: Router
    ) {
        this.startHistory();
    }

    /**
     * Wandelt einen Komponenten-Namen (Klassenname) in einen Path um, indem in app-routing.module nachgeschaut wird.
     * @param pageName zB 'DashboardPage'
     * @returns zB 'dashboard'
     */
    public getPath(pageName: string): string {
        return routes.find(item => item && item.component && item.component.name == pageName).path;
    }

    /**
     * Wandelt einen Komponenten-Namen (Klassenname) in einen Path um, indem in app-routing.module nachgeschaut wird.
     * Ersetzt außerdem die Path-Variable :id mit dem übergebenen Wert.
     * @param pageName zB 'JobboerseDetailPage'
     * @param id zB '1'
     * @returns zB 'jobboerse/1'
     */
    public getPathReplaceId(pageName: string, id: string): string {
        return this.getPathReplaceAny(pageName, { id: id });
    }

    /**
     * Wandelt einen Komponenten-Namen (Klassenname) in einen Path um, indem in app-routing.module nachgeschaut wird.
     * Ersetzt außerdem alle Path-Variablen mit den übergebenen Werten.
     * @param pageName zB 'JobboerseDetailPage'
     * @param id zB {id: 1}
     * @returns zB 'jobboerse/1'
     */
    public getPathReplaceAny(pageName: string, params: any): string {
        let path = this.getPath(pageName);
        for (let attribute in params) {
            if (Object.prototype.hasOwnProperty.call(params, attribute)) {
                path = path.replace(':' + attribute, params[attribute]);
            }
        }
        return path;
    }

    /**
     * Check, if any route is active.
     * @returns true, if any route is active
     */
    public isAnyActive(): boolean {
        return !!this.router.url?.replace('/', '')?.trim();
    }

    /**
     * Sets every page inactive and then sets the given page / subpage active
     * @param newActivePage the new active page
     */
    public resetActive(newActivePage: any): void {
        // if the page name was given instead the page itself, find the right page for it
        if (typeof newActivePage === 'string' || newActivePage instanceof String) {
            newActivePage = Pages.find(page => page.page === newActivePage);
        }

        // reset pages and subpages
        Pages.forEach(page => {
            page.active = false;
            if (!!page.sub) {
                page.sub.forEach(sub => {
                    sub.active = false;
                });
            }
        });
        // set new active pages and subpages
        Pages.forEach(page => {
            if (page === newActivePage) {
                // if page is active, set first subpage active too (if present)
                page.active = true;
                if (!!page.sub) {
                    page.sub[0].active = true;
                }
            }
            // loop over sub pages
            if (!!page.sub) {
                page.sub.forEach(sub => {
                    if (sub === newActivePage) {
                        // if sub page is active, set parent active too
                        sub.active = true;
                        page.active = true;
                    }
                });
            }
        });
    }

    /**
     * Start the recording of history. This should only be done once and only in this class.
     */
    private startHistory(): void {
        this.history = [];
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            const url = event.urlAfterRedirects;
            this.history.push(url);

            this.printCurrentHistory();

            // Emit the latest back URL
            this.backUrlSubject.next(this.getBackUrl());
        });
    }

    /**
     * Print the current history.
     */
    private printCurrentHistory(): void {
        console.debug('ROUTING: current history: ', this.history);
        console.debug('ROUTING: current back url: ', this.getBackUrl());
    }

    /**
     * Navigate back in history.
     */
    public goBack(): void {
        const backUrl = this.getBackUrl();
        if (backUrl) {
            this.router.navigateByUrl(backUrl);
            this.history.pop();
            this.history.pop();
            // Emit the new back URL after popping
            this.backUrlSubject.next(this.getBackUrl());
        }
    }

    /**
     * Get the current back-url. This changes when navigating the app.
     * @returns the current back-url.
     * @see getBackUrlObservable for keeping track of the back-url even when navigating the app
     */
    public getBackUrl(): string {
        if (this.history.length > 1) {
            return this.history[this.history.length - 2];
        }
        return '';
    }

    /**
     * Get an observable for the back-url. THis can be used to keep track of the back-url.
     * @returns the observable for the back-url.
     */
    public getBackUrlObservable(): Observable<string> {
        return this.backUrlSubject.asObservable();
    }

    /**
     * Set the current page as a root page. Root pages do not have a back URL.
     */
    public setRootPage(): void {
        // delete every entry in history except the latest, which should be the current page
        this.history.splice(0, this.history.length - 1);

        console.debug('ROUTING: deleted history and set new root page');
        this.printCurrentHistory();

        // Emit the latest back URL
        this.backUrlSubject.next(this.getBackUrl());
    }

    /**
     * Add a new back url to the history, if none exists. The given url will only be used as a fallback.
     * @param defaultBackUrl the new back url entry
     */
    public setDefaultBackUrl(defaultBackUrl: string): void {
        if (!this.getBackUrl()) {
            this.history.push(defaultBackUrl);
        }
    }
}