import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { BranchConfigService } from 'services/branch-config/branch-config';
import { ToastService } from 'services/toast/toast.service';
import { appInjector } from 'src/app/app.module';
import { ApiFilter } from './api-filter';
import { ApiListWrapper } from './api-list-wrapper';

/**
 * Abstract service for API calls. Extend this service to inherit basic API funtions.
 */
@Injectable({
  providedIn: 'root'
})
export abstract class ApiService {

  protected http: HttpClient
  protected configProvider: BranchConfigService
  protected toastService: ToastService

  constructor(
    protected prefix: string,
    @Optional() http?: HttpClient,
    @Optional() configProvider?: BranchConfigService,
    @Optional() toastService?: ToastService
  ) {
    this.http = !!http ? http : appInjector.get(HttpClient);
    this.configProvider = !!configProvider ? configProvider : appInjector.get(BranchConfigService);
    this.toastService = !!toastService ? toastService : appInjector.get(ToastService);
  }

  /**
   * GET call to given resource.
   * @param key the resource key, for example an id
   * @param options HTTP request options
   * @returns the requested object
   */
  public get(key: string | number, options?: any): Promise<any> {
    return this.httpGet('', key, options);
  }

  /**
   * GET LIST call to given resource.
   * @param filter HTTP request options
   * @returns the requested list
   */
  public getList(filter?: ApiFilter | any): Promise<any> {
    let params: HttpParams = new HttpParams();

    if (filter && typeof filter === 'object') {
      params = this.buildParams(filter);
    }

    return this.httpGet('list', '', { params });
  }

  /**
   * GET call to singleton.
   * @returns the singelton object depending on implementing service
   */
  public getSingleton(): Promise<any> {
    return this.httpGet('', '');
  }

  /**
   * GET call to get metadata.
   * @returns the metadata object depending on implementing service
   */
  public getMeta(): Promise<any> {
    return this.httpGet('meta', '');
  }

  /**
   * GET call to get filtermetadata.
   * @returns the filtermetadata object depending on implementing service
   */
  public getFilterMeta(): Promise<any> {
    return this.httpGet('filtermeta', '');
  }

  /**
   * UPDATE call for given resource
   * @param key the resource key, for example an id
   * @param data the object to update
   * @returns the updated object
   */
  public update(key: string | number, data: Object): Promise<any> {
    return this.httpPost('update', key, data);
  }

  /**
   * CREATE call for given resource
   * @param data the object to create
   * @returns the created object
   */
  public create(data: Object): Promise<any> {
    return this.httpPost('add', '', data);
  }

  /**
   * DELETE call for given resource
   * @param key the resource key, for example an id
   * @returns 
   */
  public delete(key: string | number): Promise<any> {
    return this.httpDelete('delete', key);
  }

  /**
   * Build a well formated path to use in an url.
   * @param key 
   * @returns a well formated path
   */
  protected formatPath(key: string | number): string {
    let keyString = key.toString();
    keyString = keyString.trim();
    keyString = keyString.endsWith('/') ? keyString.slice(0, -1) : keyString;
    return keyString.length == 0 || keyString.startsWith('/') ? keyString : '/' + keyString;
  }

  /**
   * Build a well formated API url.
   * @param type 
   * @param key 
   * @returns well formated API url
   */
  protected buildUrl(type: string, key: string | number): string {
    return '/api' + this.formatPath(this.prefix) + this.formatPath(type) + this.formatPath(key);
  }

  /**
   * Internal HTTP GET call.
   * @param type 
   * @param key 
   * @param options 
   * @returns the API response
   */
  protected async httpGet(type: string, key: string | number, options?: any): Promise<any> {
    await this.configProvider.isReady;
    const url = this.buildUrl(type, key);
    return this.http.get(url, options).toPromise()
      .catch(error => {
        this.toastService.handleError(error);
        console.error('ERROR loading data from ' + url + ' : ', error);
        throw error;
      });
  }

  /**
   * Internal HTTP POST call.
   * @param type 
   * @param key 
   * @param data 
   * @returns the API response
   */
  protected async httpPost(type: string, key: string | number, data?: Object): Promise<any> {
    await this.configProvider.isReady;
    const url = this.buildUrl(type, key);
    return this.http.post(url, data).toPromise()
      .catch(error => {
        this.toastService.handleError(error);
        console.error('ERROR posting data to ' + url + ' : ', error);
        throw error;
      });
  }

  /**
   * Internal HTTP PUT call.
   * @param type 
   * @param key 
   * @param data 
   * @returns the API response
   */
  protected async httpPut(type: string, key: string | number, data?: Object): Promise<any> {
    await this.configProvider.isReady;
    const url = this.buildUrl(type, key);
    return this.http.put(url, data).toPromise()
      .catch(error => {
        this.toastService.handleError(error);
        console.error('ERROR putting data to ' + url + ' : ', error);
        throw error;
      });
  }

  /**
   * Internal HTTP DELETE call.
   * @param type 
   * @param key 
   * @returns the API response
   */
  protected async httpDelete(type: string, key: string | number): Promise<any> {
    await this.configProvider.isReady;
    const url = this.buildUrl(type, key);
    return this.http.delete(url).toPromise()
      .catch(error => {
        this.toastService.handleError(error);
        console.error('ERROR deleting data from ' + url + ' : ', error);
        throw error;
      });
  }

  /**
   * Utility method to build HttpParams from an object
   * @param obj object that will be converted
   * @param prefix param prefix
   * @returns the params
   */
  protected buildParams(obj: any, prefix: string = ''): HttpParams {
    let params = new HttpParams();

    Object.entries(obj).forEach(([key, value]) => {
      const paramKey = prefix ? `${prefix}.${key}` : key;
      if (value != null) { // Check for null or undefined
        if (typeof value === 'object' && !Array.isArray(value)) {
          // Recursively flatten nested objects
          params = this.mergeHttpParams(params, this.buildParams(value, paramKey));
        } else if (Array.isArray(value)) {
          // Join array elements with commas
          params = params.append(paramKey, value.join(','));
        } else {
          // Append primitive values
          params = params.append(paramKey, String(value));
        }
      }
    });

    return params;
  }

  /**
   * Utility method to merge two HttpParams objects
   * @param params1 first param
   * @param params2 second param
   * @returns the merged params
   */
  protected mergeHttpParams(params1: HttpParams, params2: HttpParams): HttpParams {
    params2.keys().forEach(key => {
      params1 = params1.append(key, params2.get(key)!);
    });
    return params1;
  }
}
