
export class ConstantRoute {
  /* Prefixes */
  public static PREFIX_TABS: string = 't';

  /* IDs */
  public static ID_ID: string = 'id';
  public static ID_MYCOACH: string = 'my';

  /* Paths */
  public static PATH_COACHING: string = 'coaching';
  public static PATH_JOBS: string = 'jobs';
  public static PATH_EXPLORE: string = 'explore';
  public static PATH_MYACCOUNT: string = 'my-account';
  public static PATH_COACH: string = 'coach';
  public static PATH_COACH_ID: string = ConstantRoute.PATH_COACH + '/:' + ConstantRoute.ID_ID;
  public static PATH_MYCOACH: string = 'my-coach';
  public static PATH_COACHES: string = 'coaches';
  public static PATH_PRODUCT: string = 'product';
  public static PATH_PRODUCT_ID: string = ConstantRoute.PATH_PRODUCT + '/:' + ConstantRoute.ID_ID;
  public static PATH_PRODUCTS: string = 'products';
  public static PATH_ORDER: string = 'order';
  public static PATH_ORDER_ID: string = ConstantRoute.PATH_ORDER + '/:' + ConstantRoute.ID_ID;

  /* Params */
  public static PARAM_DELIMITER: string = ',';
  public static PARAM_TYPE: string = 'type';

  /* Full paths */
  public static FULL_COACHING: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_COACHING;
  public static FULL_JOBS: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_JOBS;
  public static FULL_EXPLORE: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_EXPLORE;
  public static FULL_MYACCOUNT: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_MYACCOUNT;
  public static FULL_COACH: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_COACH + '/' + ConstantRoute.ID_ID;
  public static FULL_MYCOACH: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_COACH + '/' + ConstantRoute.ID_MYCOACH;
  public static FULL_MYCOACH_REDIRECT: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_MYCOACH;
  public static FULL_COACHES: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_COACHES;
  public static FULL_PRODUCTS: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_PRODUCTS;
  public static FULL_PRODUCT: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_PRODUCT + '/' + ConstantRoute.ID_ID;
  public static FULL_ORDER: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_ORDER + '/' + ConstantRoute.ID_ID;
}
