import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, catchError, map, throwError } from 'rxjs';

/**
 * Service for handling youtube embed videos.
 */
@Injectable({
  providedIn: 'root'
})
export class YoutubeEmbedService {

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * Converts a youtube url into an embed url and sanitizes it.
   * @param videoUrl the normal youtube url
   * @returns an embed youtube url
   */
  public getEmbedUrl(videoUrl: string): Observable<SafeResourceUrl> {
    const oEmbedUrl = `https://www.youtube.com/oembed?url=${encodeURIComponent(videoUrl)}&format=json`;
    return this.http.get<any>(oEmbedUrl).pipe(
      map(response => this.extractEmbedUrl(response.html)),
      map(embedUrl => !!embedUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl) : ''),
      catchError(error => {
        console.error('ERROR converting youtube url ' + videoUrl + ' to embed: ', error);
        return throwError(() => new Error(error));
      })
    );
  }

  private extractEmbedUrl(embedHtml: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(embedHtml, 'text/html');
    const iframe = doc.querySelector('iframe');
    if (!iframe) return '';

    const srcUrl = new URL(iframe.src);
    // Append or modify YouTube parameters:
    // 1. rel=0 (limit recommendations to the same channel)
    srcUrl.searchParams.set('rel', '0');
    // 2. modestbranding=1 (hide the large YouTube logo in the control bar)
    srcUrl.searchParams.set('modestbranding', '1');
    return srcUrl.toString();
  }
}
